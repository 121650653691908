

















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: { ORegistrationFormList: () => import('@/components/organisms/globalTools/registrationForm/o-registration-form-list.vue')},

  setup(){
    return {}
  }
})
